
import Vue from "vue";
import {links} from "@/config";

export default Vue.extend({
  name: "NearestStartRegistration",
  data() {
    return {
      intro: {
        date: {
          selectedTS: 0,
          nearestTS: 0,
          availableTS: [] as number[],
          midexamTS: 0
        },
        placesLeft: 0,
        placesTotal: 30,
        loading: true,
      },
      droppedCheckbox: false
    };
  },
  computed: {
    status(): string {
      return this.roles.includes("readyToContinue")
        ? "ableToStartFromMidexam"
        : this.intro.date.selectedTS
          ? "signedUp"
          : this.intro.date.nearestTS
            ? this.intro.placesLeft > 0
              ? "ableToSignUp"
              : this.intro.date.availableTS.length > 1
                ? "ableToSignUpToNextGroup"
                : "noSeatsLeftInGroup"
            : "notSignedUp";
    },
    ableToSignUp(): boolean {
      return !["notSignedUp", "noSeatsLeftInGroup"].includes(this.status);
    },
    roles(): string[] {
      return this.$store.state.user.me.role.filter((x: string): boolean => x !== "unauthorized");
    },
    dropped(): boolean {
        return this.$store.state.user.me.dropped;
    },
    availableStartDateTS(): number {
      return this.status === "ableToSignUpToNextGroup"
          ? this.intro.date.availableTS[1]
          : this.status === "ableToStartFromMidexam"
              ? this.intro.date.midexamTS
              : this.intro.date.selectedTS || this.intro.date.nearestTS || 0;
    },
    date(): string {
      return this.$dt
          .fromSeconds(this.availableStartDateTS || 0)
          .toFormat("D");
    },
    PHPdate(): string {
        const [d, m, y] = this.date.split(".");
        return `${y}-${m}-${d}`;
    }
  },
  async mounted() {
    await this.getIntro();
  },
  methods: {
    async getIntro() {
      this.intro = (await this.$store.dispatch("user/getIntro")).data;
    },
    async toggleNearestStart() {
      await this.$store.dispatch("user/toggleNearestStart", {
        action: this.status === "ableToStartFromMidexam"
            ? "start_from_midexam"
            : this.status === "signedUp"
                ? "leave_nearest_group"
                : "join_nearest_group",
        startDate: this.PHPdate
      });
      this.$message({
        type: "success",
        message: `${this.$t("nearestGroup.confirmation." + this.status, {
            date: this.date
        })}`
      });
      await this.getIntro();
    }
  }
});
